// Insert your styling here.
.footer {
	.block {
		margin-top:10px;
		@media (min-width: 992px) {
			margin-top: 0;
		}
	}
	color: #fff;
	border-top: 0;
	margin-top: 0;
	padding-top: 0;
	.region.region-footer {
		overflow: auto;
		background: #065f9c;
		padding: 20px 0;
	}
	a {
		color: #fff;
	}
	
	.find-us-on-facebook {
		display: block;
		font-size: 40px;
		background: #fff;
		color:#065f9c;
		text-align:center;
		.border-radius(10px);
		margin-top: 10px;
		max-width: 65px;
	}
	ul.menu {
		white-space:nowrap;
		padding: 0;
		@media (min-width: 480px) {
			columns: 2;
			-webkit-columns: 2;
		    -moz-columns: 2;
		}
	}
}