.background-size(@bg-size) {
    -webkit-background-size: @bg-size;
    -moz-background-size: @bg-size;
    -o-background-size: @bg-size;
    background-size: @bg-size;
}
.border-radius(@radius: 5px) {
	-webkit-border-radius:	@radius;
	-moz-border-radius: 	@radius;
	-o-border-radius: 		@radius;
	border-radius: 			@radius;
}
.box-shadow (@string) {
	-webkit-box-shadow: @string;
	-moz-box-shadow:    @string;
	-o-box-shadow:		@string;
	box-shadow:         @string;
}
.box-sizing (@sizing: border-box) {
	-webkit-box-sizing: @sizing;
	-moz-box-sizing:    @sizing;
	box-sizing:         @sizing;
}