.flexslider {
	margin: 0 0 0px;
	background: none;
	border: 0 none;
	position: relative;
	.border-radius(0px);
	.box-shadow(0 0px 0px #000);
	zoom: 1;
	.flex-control-nav {
		@media (min-width: 768px) {
			position: absolute;
			bottom: 10px;
			z-index: 10;
		}
	}
	.flex-caption {
		background: #ef008c;
		padding: 0 10px;
		@media (min-width: 768px) {
			//width: 50%;
			padding: 0 50px 0 50px;
			position: absolute;
			bottom: 40px;
		}
		a {
			text-decoration: none;
		}
	}
	.caption {
		color: #fff;
		font-weight: bold;
		font-size: 40px;
		@media (min-width: 1200px) {
			font-size: 60px;
		}
	}
	.subcaption {
		color: #fff;
		font-weight: bold;
		font-size: 20px;
		padding-bottom: 10px;
		@media (min-width: 1200px) {
			font-size: 30px;
		}
	}
	.flex-direction-nav a {
		line-height: 40px;
	}
}

.view-flexslider-thumbs {
	.slides li:hover {
		cursor: pointer;
	}
}
