// Bootstrap library.
@import 'bootstrap.less';
@import 'bootswatch.less';
// Base-theme overrides.
@import 'overrides.less';

// Theme specific.
@import 'header.less';
@import 'content.less';
@import 'footer.less';
@import 'mixins.less';
@import 'flexslider.less';
@import 'bootstrap-ms.less';
//@import url(http://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,700italic,400,700,600);


body {
	background: url('../img/bg.jpg') no-repeat;
	.background-size(cover);
	/*font-family: 'Open Sans', sans-serif;
	font-weight: 400;*/
}