// Insert your styling here.
.front #block-system-main {
		background: #065f9c;
}
.front #block-system-main .field-type-blockreference{
	.make-md-column(4);
}
.front #block-system-main .field-name-body {
	.make-md-column(8);
	padding: 15px 35px;

}
.front #block-system-main {
	.field-name-body {
		color: #fff;
		margin-top: 15px;
		h1 {
			margin-top: 0;
			font-style: italic;
			font-weight: bold;
			font-size: 27px;
		}
		h2 {
			font-style: italic;
			font-weight: 400;
			font-size: 18px;
		}
	}
	.field-name-field-block {
		text-align:right;
		padding-right: 35px;
	}
}
.not-front #block-system-main {
	background: #065f9c;
	padding: 35px;
	color: #fff;
	a {
		color: #ffffff;
	}
	h1 {
		margin-top: 0;
	}
	.media-image {
		@media (max-width: 480px) {
			float: none !important;
		}
	}
}
/* Frontpage blocks */
#block-views-frontpage-blocks-block {
	margin-top: 15px;

	.view.view-frontpage-blocks {
		.col {
			.views-field.views-field-title, .views-field.views-field-title-field {
				color: #ffffff;
				font-size: 18px;
				font-weight: bold;
				text-align: center;
				text-transform: uppercase;
				padding: 15px 0;
				position:relative;
				margin-top: -75px;
				margin-bottom: 50px;
				a {
					color: #ffffff;
					text-decoration: none;
				}
				&.blue {
					background: #065f9c;
				}
				&.green {
					background: #4ec632;
				}
				&.orange {
					background: #ff9c18;
				}
				&.pink {
					background: #ef008c;
				}
				&.red {
					background: #ef1821;
				}
			}
		}
	}
}

/* Entity form */
.node-type-pool-contact-form {
	.entityform {
		color: #ffffff;
		::-webkit-input-placeholder {
		   color: #ffffff;
		}

		:-moz-placeholder { /* Firefox 18- */
		   color: #ffffff;
		}

		::-moz-placeholder {  /* Firefox 19+ */
		   color: #ffffff;
		}

		:-ms-input-placeholder {
		   color: #ffffff;
		}
		.control-label {
			/*display: none;*/
		}
		.form-control {
			background-color: transparent;
			border: 1px solid #387fb0;
			.border-radius(5px);
			font-style:italic;
			color: #ffffff;
		}
		textarea {
			resize: none;
		}
		.grippie {
			display:none;
		}
		#edit-submit {
			float:right;
		}
	}
}
/* Buttons override */
.btn-default {
	.border-radius(5px);
	&:hover {
		background: #007ed4;
	}
}

/* Facebook block */
#block-fb-likebox-0 {
	padding: 20px 0;
	iframe {
		background: #fff;
	}

}

/* Hoppborgar */
#block-views-hoppborgar-block {
	margin-top: 15px;
	margin-bottom: 15px;
	.view.view-hoppborgar {
		.col {
			.views-field.views-field-title, .views-field.views-field-title-field {
				color: #ffffff;
				font-size: 18px;
				font-weight: bold;
				text-align: center;
				text-transform: uppercase;
				padding: 15px 0;
				position:relative;
				margin-top: -75px;
				margin-bottom: 50px;
				a {
					color: #ffffff;
					text-decoration: none;
				}
				&.blue {
					background: #065f9c;
				}
				&.green {
					background: #4ec632;
				}
				&.orange {
					background: #ff9c18;
				}
				&.pink {
					background: #ef008c;
				}
				&.red {
					background: #ef1821;
				}
			}
		}
	}
}

/* Event calendar */
.page-event {
	#block-system-main {
		padding: 0 35px 35px;
	}
	.page-header {
		margin: 0;
		padding: 20px 35px 0;
		border-bottom: 0 none;
		background: #065f9c;
		color: #fff;
		font-style:italic;
		font-weight: bold;
		font-size: 27px;
	}
}
/* Event Page */
.node-type-event .page-header{
  display: none;
}
.node-type-event .field .label-above{
  font-weight: bold;
  font-size: 18px;
}
.node-type-event .date-display-single{
  text-transform: capitalize;
}


.view-event-calendar.view-display-id-page {
	.view-content {
		@media (min-width: 768px) {
			columns: 2;
			-webkit-columns: 2;
		    -moz-columns: 2;
		}
		div {
		  display: table;
		}
	}
	.views-bootstrap-grid-plugin-style {
		break-inside: avoid-column;
		-webkit-column-break-inside: avoid; /* Safari and Chrome */
		h3 {
			font-size: 18px;
			font-weight: bold;
			text-transform: uppercase;
		}
		.views-field.views-field-title {
			font-weight: bold;
			text-transform: uppercase;
		}
	}
}

#block-views-event-calendar-block-1 {
	.block-title {
		background: #ff9c18;
		color: #fff;
		font-weight: 600;
		font-size: 24px;
		font-style:italic;
		margin-bottom: 0;
		padding: 10px 35px;
	}
	margin-bottom: 20px;
}

.file-wysiwyg.media-element {
	max-width: 100%;
	width: 100% !important;
	height: auto !important;
}

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}