// Insert your styling here.

@media (min-width: 768px) {
	.navbar{
		background-color: transparent;
	}
}
@media (max-width: 768px) {
	.logo img {
		max-width: 100px;
	}
}
.navbar {
	margin-bottom: 0;
}
.navbar-collapse {
	clear: both;
	background: #ef008c;
}
.navbar-nav > li > a {
	text-transform: uppercase;
	font-weight: bold;
}
ul.nav li.dropdown:hover ul.dropdown-menu {
  display: block;
}
ul.nav li.dropdown ul.dropdown-menu {
  margin-top: 0;
}

#block-locale-language {
	.language-switcher-locale-url {
		list-style: none;
		position: relative;
		top:12.5px;
		li {
			display: inline-block;
			a {
				color: #fff;
				padding-top: 12.5px;
				padding-bottom: 12.5px;
				line-height: 20px;
				font-size: 13px;
				text-transform: uppercase;
				font-weight: bold;
				margin: 0 5px;
				text-decoration: none;
				&.active {
					color: yellow;
				}
			}
		}
	}
}
